exports.linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === 'blog_post') {
    return `/blog/${doc.uid}/`
  }
  if (doc.type === 'flutter_post') {
    return `/flutter-blog/${doc.uid}/`
  }

  // Backup for all other types
  return '/'
}
